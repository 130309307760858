import localforage from "localforage";
import React, { useEffect, useContext } from "react";
import { createContext } from "react";
import { useState } from "react";
import { decodeToken } from "react-jwt";
import { useLocation, useNavigate } from "react-router-dom";
import { confirm, getAuth, request, sendAlert } from "../module/util";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [auth, setAuth] = useState({});
  const [token, setToken] = useState("");
  const [title, setTitle] = useState("");
  const [check, setCheck] = useState(true);
  const user = () => {
    if (!auth.urf_token)
      localforage.getItem("token").then((value) => {
        if (value) {
          setAuth(decodeToken(value));
          setToken(value);
        }
      });
  };

  const logout = (err) => {
    localforage.removeItem("token");
    navigate("/sign-in");
    if (err) sendAlert(false, err);
  };
  useEffect(() => {
    setTimeout(() => {
      localforage.getItem("token", function (err, value) {
        if (!value) logout();
        let date = new Date(value["expire"]).getTime();
        var date2 = new Date().getTime();
        if (date <= date2) {
          logout();
        }
      });
      user();
    }, 100);
    setTitle("");
  }, [location]);
  const handleDelete = (id, url, redirect) => {
    confirm().then(() => {
      request(`/${url}/${id}`, "DELETE").then((res) => {
        if (res["msg"]) {
          sendAlert(true, res["msg"]);
          navigate(redirect);
        } else if (res["error"]) logout(res["error"]);
      });
    });
  };
  const authContextValue = {
    user,
    logout,
    auth,
    token,
    title,
    setTitle,
    handleDelete,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
