/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Drawer, Affix, Card } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import routes from "../../module/routes";
import { AuthProvider } from "../AuthProvider";
import { Dashboard } from "./Dashboard";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState('');
  const [subname, setSubname] = useState('');

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);
  let location = useLocation();
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");


  useEffect(() => {
    routes.map((item) => {
      if (pathname == item.path.replace("/", "")) {
        if (item.breadcrumb)
          setBreadcrumb(item.breadcrumb)
        if (item.name)
          setSubname(item.name)
      }
    })
  }, [location])
  return (
    <AuthProvider>
      <Layout
        className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
          } ${"layout-dashboard-rtl"}`}
      >
        <div id="loader_body"></div>
        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          key={placement === "right" ? "left" : "right"}
          width={250}
          className={`drawer-sidebar ${"drawer-sidebar-rtl"
            } `}
        >
          <Layout
            className={`layout-dashboard ${"layout-dashboard-rtl"
              }`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
                }`}
              style={{ background: sidenavType }}
            >
              <Sidenav color={sidenavColor} />
            </Sider>
          </Layout>
        </Drawer>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
            }`}
          style={{ background: sidenavType }}
        >
          <Sidenav color={sidenavColor} />
        </Sider>
        <Layout>
          {fixed ? (
            <Affix>
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={subname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                // breadcrumb={breadcrumb}
                />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={subname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
                breadcrumb={breadcrumb}
              />
            </AntHeader>
          )}
          <Content className="content-ant">
            <Dashboard subname={subname} breadcrumb={breadcrumb} children={children} />
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </AuthProvider>

  );
}

export default Main;
