import { createBrowserHistory } from "@remix-run/router";
import { Pagination as Pages } from "antd";
import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getParams } from "../module/util";

const Pagination = ({ all, page, setPage, getData }) => {
  const location = useLocation();
  const history = createBrowserHistory();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = (p) => {
    setPage(p);
    // getData(p);
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.set("page", p);
      return newSearchParams.toString();
    });
  };
  return (
    <Pages
      style={{ direction: "ltr" }}
      className="text-center ma-4"
      hideOnSinglePage
      pageSize={1}
      total={all}
      showSizeChanger={false}
      onChange={onChange}
      responsive
      current={parseInt(page)}
    />
  );
};
export default Pagination;
