import Home from "./../pages/Home";
import Project from "./../pages/Project";
import SignIn from "./../pages/SignIn";
import Main from "./../components/layout/Main";
import ErrorPage from "./../pages/ErrorPage";
import { DollarOutlined, FormOutlined, HomeOutlined, HourglassOutlined, ProjectOutlined, SettingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import { BreadCrumb } from "../components/BreadCrumb";
import { Post } from "../pages/posts/Post";
import { Category } from "../pages/categories/Category";
import { Tag } from "../pages/posts/Tag";
import { Comment } from "../pages/posts/Comment";
import { Setting } from "../pages/settings/Setting";
import { Menu } from "../pages/settings/Menu";
import { SavePost } from "../pages/posts/SavePost";
import { PostView } from "../pages/posts/PostView";
import { SaveCategory } from "../pages/categories/SaveCategory";
import { CategoryView } from "../pages/categories/CategoryView";
import { User } from "../pages/users/User";
import { SaveUser } from "../pages/users/SaveUser";
import { UserView } from "../pages/users/UserView";
import { Applicant } from "../pages/users/Applicant";
import { Skill } from "../pages/Skill";
import { Role } from "../pages/Role";
import { Transaction } from "../pages/Transaction";
import { Arbitration } from "../pages/Arbitration";

let routes = [
    {
        path: "/",
        element: <Main><Home /></Main>,
        errorElement: <Main><ErrorPage /></Main>,
        name: 'داشبورد',
        icon: <HomeOutlined />,

    },
    {
        path: "/sign-in",
        element: <SignIn />,
    },
];

export default routes;