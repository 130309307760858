/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { CaretLeftOutlined } from "@ant-design/icons";
import { Menu, Button } from "antd";

import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import routes from "../../module/routes";

function Sidenav({ color }) {
  const { pathname, search } = useLocation();
  const page = pathname;

  const handleCache = () => {
    console.log('click')
  }
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>پایگان</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {
          routes.map((item, k) => (
            <>
              {item.name && !item.no_menu ?
                <>
                  {
                    item.subMenu ?
                      <Menu.SubMenu title={item.name} icon={<span
                        className="icon"
                      >
                        {item.icon}
                      </span>} className={'nav-sub-menu '}>
                        {
                          item.subMenu.map((menu, m) => (
                            <Menu.Item key={`sub-${k}-${m}`} className="mr-3">
                              {
                                menu.path ?
                                  <NavLink to={menu.path}>
                                    <span
                                      className="icon"
                                      style={{
                                        background: page === menu.path ? color : "",
                                        width: 20, height: 20
                                      }}
                                    >
                                      <CaretLeftOutlined />
                                    </span>
                                    <span className="label">{menu.name}</span>
                                  </NavLink> :
                                  <a onClick={menu.onClick == 'handleCache' ? handleCache : ''}>
                                    <span
                                      className="icon"
                                      style={{
                                        background: page === menu.path ? color : "",
                                        width: 20, height: 20
                                      }}
                                    >
                                      <CaretLeftOutlined />
                                    </span>
                                    <span className="label">{menu.name}</span>
                                  </a>
                              }

                            </Menu.Item>
                          ))
                        }
                      </Menu.SubMenu>
                      : <Menu.Item key={k}>
                        <NavLink to={item.path}>
                          <span
                            className="icon"
                            style={{
                              background: page === item.path ? color : "",
                            }}
                          >
                            {item.icon}
                          </span>
                          <span className="label">{item.name}</span>
                        </NavLink>
                      </Menu.Item>
                  }

                </>
                : ''}
            </>
          ))
        }

      </Menu>
    </>
  );
}

export default Sidenav;
