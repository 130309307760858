/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Tooltip,
  Divider,
} from "antd";
import face2 from "../assets/images/face-2.jpg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirm, getParams, request, sendAlert } from "../module/util";
import { EyeOutlined } from "@ant-design/icons";
import SaveProject from "../components/forms/SaveProject";
import Pagination from "../components/Pagination";
import { useAuthContext } from "../components/AuthProvider";
import Filters from "../components/Filters";

const { Title } = Typography;

// table code start

export default function Project() {
  const columns = [
    {
      title: "شناسه",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "عنوان",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "مدیر پروژه",
      key: "extenal_manager",
      dataIndex: "extenal_manager",
    },
    {
      title: "وضعیت",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "عملیات",
      key: "action",
      dataIndex: "action",
    },
  ];
  const { logout } = useAuthContext();
  const location = useLocation();
  let getPage = getParams(location.search, "page");
  getPage = getPage ? getPage : 1;
  const navigate = useNavigate();
  const [model, setModel] = useState([]);
  const [manager, setManager] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState({});
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(getPage);
  const getProject = (params) => {
    request(`/projects?${params}`).then((res) => {
      if (res["data"]) {
        setModel(res["data"]);
      } else if (res["error"]) logout(res["error"]);

      if (res["count"]) setPages(res["count"]);
      else setPages(1);
    });
  };
  const getManager = () => {
    request(`/projects/manager`).then((res) => {
      if (res["data"]) {
        setManager(res["data"]);
      } else if (res["error"]) logout();
    });
  };
  // useEffect(() => {
  //   getProject(page);
  // }, [location]);
  useEffect(() => {
    getManager();
  }, []);
  const handleEdit = (m) => {
    setShowModal(true);
    setModalData(m);
  };
  const handleStatus = (status, id) => {
    confirm().then(() => {
      request("/projects/status", "PUT", { id: id, status: status }).then(
        (res) => {
          if (res["msg"]) {
            sendAlert(true, res["msg"]);
            navigate("/projects");
          } else if (res["error"]) logout(res["error"]);
        }
      );
    });
  };
  useEffect(() => {
    let newData = [];
    model.map((item, k) => {
      newData.push({
        key: ++k,
        id: <span>{item.id}</span>,
        name: <span>{item.title}</span>,
        extenal_manager: <span>{item.extenal_manager}</span>,
        status: <span>{item.status}</span>,
        action: (
          <div>
            <Tooltip placement="top" title={"انتخاب مدیر"} color="#1890ff">
              <button onClick={() => handleEdit(item)}>
                <i class="fa fa-pencil text-primary" aria-hidden="true"></i>
              </button>
            </Tooltip>
            <Tooltip placement="top" title={"تایید پروژه"} color="#52c41a">
              <button onClick={() => handleStatus(1, item.id)}>
                <i class="fa fa-check text-success" aria-hidden="true"></i>
              </button>
            </Tooltip>
            <Tooltip placement="top" title={"رد پروژه"} color="#F1212C">
              <button onClick={() => handleStatus(0, item.id)}>
                <i class="fa fa-close text-danger" aria-hidden="true"></i>
              </button>
            </Tooltip>
          </div>
        ),
      });
    });
    setData(newData);
  }, [model]);
  const filters = [
    { name: "id", type: "text", label: "شناسه" },
    { name: "title", type: "text", label: "عنوان" },
    {
      name: "manager",
      type: "select",
      options: manager,
      label: "مدیر",
      mode: "multiple",
    },
    {
      name: "req",
      type: "select",
      options: [
        { value: "1", label: "بله" },
        { value: "0", label: "خیر" },
      ],
      label: "درخواست مدیر",
    },
  ];
  return (
    <>
      <div className="tabled">
        <Filters fields={filters} getData={getProject} />
        <Divider />
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            className="ant-border-space"
          />
        </div>
        <Pagination all={pages} page={page} setPage={setPage} />
        <SaveProject
          manager={manager}
          title={"انتخاب مدیر"}
          show={showModal}
          setShow={setShowModal}
          data={modalData}
          setData={setModalData}
          page={page}
        />
      </div>
    </>
  );
}
