import { Card } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import { useAuthContext } from "../AuthProvider";

export const Dashboard = ({ subname, breadcrumb, children }) => {
  const { title } = useAuthContext();
  return (
    <>
      <Helmet>
        <title>{`داشبورد پایگان | ${subname} ${title}`}</title>
      </Helmet>
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title={
          <div>
            <span>{subname + " " + title}</span>
            <div>{breadcrumb}</div>
          </div>
        }
      >
        {children}
      </Card>
    </>
  );
};
