/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

import { Typography } from "antd";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import Map from "../components/Map";

function Home() {
  const { Title, Text } = Typography;
  const [markers, setMarkers] = useState({});
  const [newMarker, setNewMarker] = useState([]);
  const location = useLocation();
  const socketConnection = (client_type, router) => {
    let socket = io("https://socket.payeganltd.com:5959");

    socket.on("connection", (data) => {
      console.log("ffffff: " + data);
      data.on("chat message", (msg) => {
        console.log("message: " + msg);
      });
    });

    socket.on("connect", (data) => {
      socket.emit("joinRoom", "1");
    });
    socket.off("joinRoom");
    socket.on("joinRoom", (data) => {
      console.log("joinRoom: " + data);
    });
    socket.off("receiveOrder");
    socket.on("receiveOrder", (message) => {
      let ex = message[1].split("|");
      markers[message[0]] = [ex[0], ex[1].split(",")];
      setMarkers(markers);
      let new_marker = [];
      for (const key in markers) {
        new_marker.push(markers[key]);
      }
      setNewMarker(new_marker);
    });
  };
  useEffect(() => {
    socketConnection();
  }, [location]);
  return (
    <>
      <div className="layout-content">
        <Title level={1}>داشبورد</Title>

        <Map markers={newMarker} />
      </div>
    </>
  );
}

export default Home;
