import React from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import loader from "../assets/img/loader.gif";
import { decodeToken } from 'react-jwt';
import localforage from 'localforage';

export function request(url, method, data) {
    var div = document.getElementById('loader_body');
    let ex = url.split('?');
    if (div) {
        div.innerHTML += `<div id="" class="loader ${ex[0]}"><div className="loader"><img src="${loader}" alt="" /></div></div>`;
    }
    return new Promise((resolve, reject) => {
        localforage.getItem("token").then((user_token) => {

            var requestOptions = {
                method: method ? method : 'GET',
                redirect: 'follow'
            };
            if (data) {
                requestOptions['body'] = check_data(data);
            }


            var myHeaders = new Headers();
            if (user_token) {
                myHeaders.append("Authorization", `Bearer ${user_token}`)
            }
            myHeaders.append("Content-Type", "application/json");
            requestOptions['headers'] = myHeaders;
            fetch((process.env.REACT_APP_BE_URL + url), requestOptions)
                .then(response => response.json())
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject()
                }).finally(() => {
                    let el = document.getElementsByClassName(ex[0]);
                    if (el.length) {
                        for (let index = 0; index < el.length; index++) {
                            el[index].remove();
                        }
                    }
                })
        });

    });
};
export const check_data = (data) => {
    for (const key in data) {
        if (data[key] === undefined)
            data[key] = '';
    }
    return JSON.stringify(data);
}

export const getAuth = async () => {
    let token = await localforage.getItem('token');
    let user = {};
    if (token) {
        user = decodeToken(token);
    }
    return user;
}
export function getFormData(data) {
    let form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    return form_data;
}

export const sendAlert = (success = false, msg) => {
    const option = {
        position: "bottom-right",
        style: { direction: "rtl", 'text-align': 'right', fontFamily: "IRANSansX", 'white-space': 'pre-line' },
        theme: 'colored'
    };
    if (success)
        toast.success(msg, option);
    else
        toast.error(msg, option);
}


export function getParams(params, name, createParams = 0, removePage = 0) {
    if (createParams == 1 && params.search('&')) {
        let new_params = '';
        let a = params.split('&');
        for (let i = 0; i < a.length; i++) {
            if (removePage == 1 && a[i].search('page') != -1)
                continue
            new_params += (a[i].replace('?', '') + '&');
        }
        return new_params.substring(0, new_params.length - 1);
    } else
        return new URLSearchParams(params).get(name);
}
export const confirm = () => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            title: 'تایید عملیات',
            icon: <ExclamationCircleOutlined />,
            content: 'آیا مطمئن هستید؟',
            okText: 'بله',
            cancelText: 'خیر',
            direction: 'rtl',
            closable: true,
            maskClosable: true,
            onOk: () => {
                resolve(true)
            },
        });
    });
};

export function convert_obj_to_form_data(obj) {
    let data = new FormData();
    for (const key in obj) {
        let val = obj[key];
        if (val) {
            if (typeof val === 'object') {
                for (const k in val) {
                    data.append(`${key}[]`, val[k])
                }
            } else {
                data.append(key, val)
            }
        }
    }
    return data;
}
export function numberFormat(price) {
    let formattedPrice = (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '');
    return formattedPrice

}
export const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
export const e2p = s => s.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])

export function maskm(e) {
    let val = e.target.value;
    val = p2e(val).replace(/\D/g, '');
    val = val.replace(/,/g, '');
    e.target.value = numberFormat(val);
    val = (val ? parseInt(val) : 0);
    return val;
}
export function maskm2(val) {
    val = p2e(val).replace(/\D/g, '');
    val = val.replace(/,/g, '');
    val = numberFormat(val);
    return val;
}