
// import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/style.css";
import "./assets/css/font-awesome.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'antd-css-utilities/utility.min.css'
import { ToastContainer } from 'react-toastify';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import routes from "./module/routes";
const router = createBrowserRouter(routes);
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
