import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  message,
} from "antd";
import { request, sendAlert } from "../module/util";
import localforage from "localforage";
import logo from "../assets/img/logo.png";

function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;

export default function SignIn() {
  const navigate = useNavigate();
  const onFinish = (data) => {
    request("/users/login-backend", "POST", data).then((res) => {
      if (!res["success"]) {
        sendAlert(false, res["message"]);
      } else if (res["data"]["token"]) {
        sendAlert(true, "با موفقیت وارد شدید");
        localforage.setItem("token", res["data"]["token"]);
        navigate("/");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Layout className="layout-default layout-signin" dir="rtl">
        <Header>
          <div className="header-col header-brand">
            <h5>
              <img src={logo} alt="" width={58} height={58} />
            </h5>
          </div>
        </Header>
        <div id="loader_body"></div>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">ورود</Title>
              <Title className="font-regular text-muted" level={5}>
                برای ورود نام کاربری و رمز عبور خود را وارد کنید
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="نام کاربری"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "نام کاربری را وارد کنید",
                    },
                  ]}
                >
                  <Input placeholder="نام کاربری" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="کلمه عبور"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "کلمه عبور را وارد کنید",
                    },
                  ]}
                >
                  <Input placeholder="کلمه عبور" type="password" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="aligin-center text-left"
                  valuePropName="checked"
                >
                  <Switch defaultChecked onChange={onChange} id="remember" />
                  <label className="me-3" htmlFor="remember">
                    مرا بخاطر بسپار
                  </label>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    ورود
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              {/* <img src={signinbg} alt="" /> */}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
