import { Row, Col, Card, Table, Tooltip, Divider, Button } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { confirm, getParams, request, sendAlert } from "../module/util";
import Pagination from "../components/Pagination";
import { useAuthContext } from "../components/AuthProvider";
import Filters from "../components/Filters";
import SaveTag from "../components/forms/SaveTag";
import SaveSkill from "../components/forms/SaveSkill";
import SaveArbitration from "../components/forms/SaveArbitration";

// table code start

export const Arbitration = () => {
  const { logout, handleDelete } = useAuthContext();
  const location = useLocation();
  let getPage = getParams(location.search, "page");
  getPage = getPage ? getPage : 1;
  const [model, setModel] = useState([]);
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(getPage);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const getData = (params) => {
    request(`/arbitrations?${params}`).then((res) => {
      if (res["data"]) {
        setModel(res["data"]);
      } else if (res["error"]) logout(res["error"]);
      if (res["count"]) setPages(res["count"]);
      else setPages(1);
    });
  };
  const columns = [
    {
      title: "شناسه",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "فریلنسر",
      dataIndex: "freelancer_name",
      key: "freelancer_name",
    },
    {
      title: "کارفرما",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "توضیح فریلنسر",
      dataIndex: "freelancer_text",
      key: "freelancer_text",
    },
    {
      title: "توضیح کارفرما",
      dataIndex: "client_text",
      key: "client_text",
    },
    {
      title: "رای داور",
      dataIndex: "result",
      key: "result",
    },
    {
      title: "توضیح دوم فریلنسر",
      dataIndex: "freelancer_text2",
      key: "freelancer_text2",
    },
    {
      title: "توضیح دوم کارفرما",
      dataIndex: "client_text2",
      key: "client_text2",
    },
    {
      title: "رای نهایی داور",
      dataIndex: "result2",
      key: "result2",
    },
    {
      title: "پروژه",
      dataIndex: "project_fa",
      key: "project_fa",
    },
    {
      title: "وضعیت",
      dataIndex: "status__fa",
      key: "status__fa",
    },
    {
      title: "عملیات",
      key: "action",
      dataIndex: "action",
    },
  ];
  useEffect(() => {
    let newData = [];
    model.map((item, k) => {
      newData.push({
        key: ++k,
        id: <span>{item.id}</span>,
        freelancer_name: <span>{item.freelancer_name}</span>,
        client_name: <span>{item.client_name}</span>,
        freelancer_text: <span>{item.freelancer_text}</span>,
        client_text: <span>{item.client_text}</span>,
        result: <span>{item.result}</span>,
        freelancer_text2: <span>{item.freelancer_text2}</span>,
        client_text2: <span>{item.client_text2}</span>,
        result2: <span>{item.result2}</span>,
        project_fa: <span>{item.project_fa}</span>,
        status__fa: <span>{item.status__fa}</span>,
        action: (
          <div>
            {["1", "3"].includes(item.status) ? (
              <Tooltip placement="top" title={"ویرایش"} color="#1890ff">
                <button onClick={() => handleEdit(item)}>
                  <i class="fa fa-pencil text-primary" aria-hidden="true"></i>
                </button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        ),
      });
    });
    setData(newData);
  }, [model]);

  const handleEdit = (m) => {
    setShowModal(true);
    setModalData(m);
    setModalTitle(m.name ? `ویرایش مهارت: ${m.name}` : "ثبت مهارت جدید");
  };
  return (
    <>
      <Filters fields={[]} getData={getData} hide_form={true} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="ant-border-space"
        />
      </div>
      <Pagination all={pages} page={page} setPage={setPage} getData={getData} />
      <SaveArbitration
        title={modalTitle}
        show={showModal}
        setShow={setShowModal}
        data={modalData}
        setData={setModalData}
        model={model}
      />
    </>
  );
};
